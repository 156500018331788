
// import Dropdown1 from "../../../components/dropdown/Dropdown1.vue";

import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import router from "@/router";
import ExportLog from "@/components/modals/forms/ExportLog.vue";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { ElLoading } from "element-plus";
import { ElNotification } from "element-plus";
import { saveAs } from "file-saver";
import { Field, Form } from "vee-validate";

interface ICustomer {
  id: string;
  name: string;
  group: string;
  email: string;
}

export default defineComponent({
  name: "dashbord",
  mixins: [Globals],
  components: {
    ExportLog,
  },
  data() {
    return {
      request_data_loaded: true,
      x: "",
      childStatus: "",
      initialPageSize: 25,
      symbolFlag: true,
      searchQuery: "",
      orgData: [] as any,
      flag: false,
      configData: [],
      status: [] as any,
      apiDataChild: [] as any,
      apiData: [],
      render: false,
      initCust: [],
      search: "",
      searchFlag: true,
      manufactId: "",
      array: [] as any,
      apiLogDateRangeError: "",
      track: "",
      trackFlag: true,
      sortBy: "",
      sortDirection: "asc",
      temp: [],
      requestIdProp: "",
      logsTimeStamp: "",
      pageNumber: 0,
      displayPage: 1,
      nextDisabled: false,
      prevDisabled: true,
      dialogVisible: false,
      apiData1: [],
      apiData2: [],
      showcalls: true,
      show_error: false,
      dataRange: "",
      searchUserKey: "",
      norequestid: false,
      active: false,
      request: true,
      visible: true,
      buttonActive: false,
      requestLog: true,
      responseLog: false,
      consumerId: "",
      manufacturerId: "",
      api: "",
      requestId: "",
      responseCode: "",
      logdetails: "",
      AllDatAattachedAPI: [] as any,
      AllDatattachedManufacturer: [] as any,
      AllDatattachedConsumer: [] as any,
      group1: "",
      group2: "",
      request_id: "",
      journey_id: "",
      shortcuts: [
        {
          text: "Last week",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "Last month",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
      value2: "",
      today: new Date(),
      startDate: "",
      endDate: "",
      requestResponesDate: process.env.VUE_APP_REQUEST_RESPONSE_LAST_DATE,
      requestResponesNoteText: process.env.VUE_APP_NOTE_TEXT,
      requestResponesNoteText2: process.env.VUE_APP_NOTE_TEXT2
    };
    
    
  },

  mounted() {
    this.calculateDate();
    this.getApiLog();
    if (
      this.getOrgRole() === "manufacturer" ||
      this.getOrgRole() === "consumer"
    ) {
      this.filterapi();
    }
  },
  methods: {
    filterfunction(x) {
      this.dateRange(x);
      this.getApiLog();
    },
    exportLog(orgId, orgRol, initSize, pageNum) {
      (this.$refs["exportLogForm"] as any).callUpdate(orgId, orgRol);
    },
    download(){
      //  this.loading = true;
      var keys = Object.keys(this.apiData[0]);
      let csv = "";
      csv += keys.join(",") + "\n";
      for (var line of this.apiData) {
        csv += keys.map((key) => JSON.stringify(line[key])) + "\n";
      }
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      // this.loading = false;
      saveAs(blob, "API Logs.csv");
    },
    resetFunc() {
      this.value2 = ""
      this.group1 = "";
      this.group2 = "";
      this.request_id = "";
      this.journey_id = "";
    },
    filterapi() {
      ApiService.get(
        "/apiLog/filterInfo?organizationId=" +
          this.globalData.organizationId +
          "&organizationType=" +
          this.getOrgRole()
      )
        .then((data) => {
          // loading.close()
          this.ALLData = JSON.parse(JSON.stringify(data.data.data));
          this.AllDatAattachedAPI = JSON.parse(
            JSON.stringify(data.data.data.attachedAPI)
          );
          if (this.getOrgRole() == "manufacturer") {
            this.AllDatattachedConsumer = JSON.parse(
              JSON.stringify(data.data.data.attachedConsumer)
            );
          } else if (this.getOrgRole() == "consumer") {
            this.AllDatattachedManufacturer = JSON.parse(
              JSON.stringify(data.data.data.attachedManufacturer)
            );
          } else {
            this.AllDatattachedConsumer = JSON.parse(
              JSON.stringify(data.data.data.attachedConsumer)
            );
            this.AllDatattachedManufacturer = JSON.parse(
              JSON.stringify(data.data.data.attachedManufacturer)
            );
          }
        })
        .catch((error) => {
          console.log(error);
          //  loading.close()
          //  if(error.response.status===401)
          //  {
          //     router.push({ name: "sign-in" });
          //  }
        });
    },

    showLatest() {
      this.resetFunc();
      this.calculateDate();
      this.initialPageSize = 25;
      this.pageNumber = 0;
      this.displayPage =  this.pageNumber + 1;
      this.getApiLog();
    },
    goBack() {
      this.resetFunc();
      this.calculateDate();
      this.getApiLog();
    },
    next() {
      if (this.apiData.length >= 10) {
        this.pageNumber = this.pageNumber + 1;
        this.displayPage = this.pageNumber + 1;
        this.getApiLog();
        this.nextDisabled = false;
        this.prevDisabled = false;
      } else if (this.apiData.length == 0) {
        ElNotification({
          title: "Error",
          message: "No more API logs",
          type: "error",
          duration: 2000,
          position: "top-right",
        });
        this.nextDisabled = true;
      } else {
        ElNotification({
          title: "Error",
          message: "No more API logs",
          type: "error",
          duration: 2000,
          position: "top-right",
        });
      }
    },
    previous() {
      if (this.pageNumber != 0) {
        this.pageNumber = this.pageNumber - 1;
        this.displayPage = this.pageNumber + 1;

        this.getApiLog();
      } else {
        ElNotification({
          title: "Error",
          message: "Already on first page",
          type: "error",
          duration: 2000,
          position: "top-right",
        });
        this.prevDisabled = true;
      }
    },
    calculateDate() {
      let current = new Date().getFullYear();
      let month = new Date().getMonth();
      let todayDate = new Date();
      this.endDate = todayDate.toISOString().replace("T", " ").split(".")[0];
      var d = new Date();
      d.setDate(d.getDate()-7);

      //for (let i = 0; i < 1; i++) {
        //d.setMonth(d.getMonth() - 1);
      //}
      // debugger;
      this.startDate = d.toISOString().replace("T", " ").split(".")[0];
    },
    dateRange(date) {
      if (date == null || date == "") {
        // this.getApiLog()
        // ElNotification({
        //   title: "Error",
        //   message: "Date cannot be null",
        //   type: "error",
        //   duration: 2000,
        //   position: "top-right",
        // });
         this.calculateDate()
        // let todayDate = new Date();
        // var d = new Date();
        // d.setDate(1);

        // for (let i = 0; i < 11; i++) {
        //   d.setMonth(d.getMonth() - 1);
        // }
        // this.startDate = d.toISOString().replace("T", " ").split(".")[0];
        // this.endDate = todayDate.toISOString().replace("T", " ").split(".")[0];
        // this.getApiLog();
      } else {
        // debugger;
        this.startDate = this.convertDateToString(date[0]);
        this.endDate = this.convertDateToString(date[1]);
        // this.startDate = date[0].toISOString().replace("T", " ").split(".")[0];
        // this.endDate = date[1].toISOString().replace("T", " ").split(".")[0];
        
        // this.getApiLog();
      }
    },
    sorting(s) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.sortBy = s;
    },
    resultQuery() {
      this.apiData.splice(0, this.apiData.length, ...this.initCust);
      if (this.searchQuery !== "") {
        let results = [];
        for (let j = 0; j < this.apiData.length; j++) {
          if (this.searchingFunc(this.apiData[j], this.searchQuery)) {
            results.push(this.apiData[j]);
          }
        }
        this.apiData.splice(0, this.apiData.length, ...results);
        if (this.apiData.length == 0 && this.searchFlag == true) {
          this.searchFlag = false;
          ElNotification({
            title: "Warning",
            message: "No Records Found!",
            type: "warning",
            duration: 2000,
            position: "top-right",
          });
        } else if (this.apiData.length != 0) {
          this.searchFlag = true;
        }
      }
    },
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    displayData(data) {
      this.request = false;
      this.apiData = JSON.parse(JSON.stringify(data));

      if (this.apiData.length != 0) {
        this.render = true;
      } else {
        this.render = false;
        this.show_error = true;
      }
      this.initCust.splice(0, this.apiData.length, ...this.apiData);
    },
    getApiLog() {
      this.initialPageSize = Number(this.initialPageSize);
      this.render = false;
      this.norequestid = false;
      this.show_error = false;
      var link = "";
      if (this.group1 == "" && this.group2 == "") {
        link =
          "/apiLog?organizationId=" +
          this.globalData.organizationId +
          "&organizationType=" +
          this.getOrgRole() +
          "&startDate=" +
          this.startDate +
          "&endDate=" +
          this.endDate +
          "&pageSize=" +
          this.initialPageSize +
          "&pageNumber=" +
          this.pageNumber;
      } else if (this.group1 == "") {
        link =
          "/apiLog?organizationId=" +
          this.globalData.organizationId +
          "&organizationType=" +
          this.getOrgRole() +
          "&startDate=" +
          this.startDate +
          "&endDate=" +
          this.endDate +
          "&pageSize=" +
          this.initialPageSize +
          "&pageNumber=" +
          this.pageNumber +
          "&apiId=" +
          this.group2;
      } else if (this.group2 == "") {
        link =
          "/apiLog?organizationId=" +
          this.globalData.organizationId +
          "&organizationType=" +
          this.getOrgRole() +
          "&startDate=" +
          this.startDate +
          "&endDate=" +
          this.endDate +
          "&pageSize=" +
          this.initialPageSize +
          "&pageNumber=" +
          this.pageNumber +
          "&filterOrgId=" +
          this.group1;
      } else {
        link =
          "/apiLog?organizationId=" +
          this.globalData.organizationId +
          "&organizationType=" +
          this.getOrgRole() +
          "&startDate=" +
          this.startDate +
          "&endDate=" +
          this.endDate +
          "&pageSize=" +
          this.initialPageSize +
          "&pageNumber=" +
          this.pageNumber +
          "&filterOrgId=" +
          this.group1 +
          "&apiId=" +
          this.group2;
      }
      if (this.request_id != ""){
        link = link + "&requestId=" + this.request_id
      }
      if (this.journey_id != ""){
        link = link + "&journeyId=" + this.journey_id
      }
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(link)
        .then((data) => {
          loading.close();
          this.displayData(data.data.data);
        })
        .catch((error) => {
          this.render = false;
          this.show_error = true;
          loading.close();
          if (error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
          this.apiLogDateRangeError = JSON.stringify(
            error.response.data["errors"][0]["errorMessage"]
          );
          ElNotification({
            title: "Error",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    getEvents(requestId, index) {
      this.x = index;
     let elements = document.getElementsByClassName("fas fa-eye fa-stack-3x fw-bold");
    for (var i = 0; i < elements.length; i++) {
          document.getElementById(elements[i].id).style.color="";

    }
      if(document.getElementById(index).style.color=="red"){
        document.getElementById(index).style.color="";
      }
      else{
        document.getElementById(index).style.color="black"
      }
      if(this.childStatus===index)
      {
        this.childStatus=""
      }
       else
       {
       
         this.childStatus=index
      ApiService.get("/apiLog/events?awsrequestId=" + requestId)

        .then((data) => {
          // loading.close();
          let data1 = [];
          data1 = JSON.parse(JSON.stringify(data.data.data));

          this.apiDataChild = data1;

          // this.render = true
        })
        .catch((error) => {
          this.apiDataChild = [];
          this.apiLogDateRangeError = JSON.stringify(
            error.response.data["errors"][0]["errorMessage"]
          );
        });
        
       }
     
    },
    displayData2(data) {
      this.apiData2 = JSON.parse(JSON.stringify(data));
    },
    requestResponse(id) {
      this.apiData1 = [];
      this.apiData2 = [];
      this.request_data_loaded = false;
      ApiService.get("/apiLog/requestResponse?apiUsageId=" + id)
        .then((data) => {
          if (data.data){
            this.displayData2(data.data.data);
          }
          this.request_data_loaded = true;
        })
        .catch((error) => {
          this.apiLogDateRangeError = JSON.stringify(
            error.response.data["errors"][0]["errorMessage"]
          );
          ElNotification({
            title: "Error",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    convertDateToString(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');

      const formattedDate = `${year}-${month}-${day} 00:00:00`;
      // formattedDate = formattedDate + " 00:00:00"
      return formattedDate;
    },
    copy(text) {
      let copytext = {
        "requestPayload": this.apiData2['requestPayload'],
        "responsePayload": this.apiData2['responsePayload']
      };
      navigator.clipboard
        .writeText(JSON.stringify(text))
        .then(() => {
          ElNotification({
            title: "Copy",
            message: "Copied to Clipboard",
            type: "success",
            duration: 2000,
            position: "top-right",
          });
        })
        .catch(() => {
          ElNotification({
            title: "Error",
            message: "Copied to Clipboard failed",
            type: "warning",
            duration: 2000,
            position: "top-right",
          });
        });
    },
    isRequestResponseDetailLastDate(timestamp){
      if (timestamp && this.requestResponesDate && 
        new Date(this.requestResponesDate) > new Date(new Date(timestamp).setMinutes(new Date(timestamp).getMinutes() - new Date(timestamp).getTimezoneOffset()))) {
        return true
      }
      else {
        return false
      }
    },
  },

  computed: {
    sorted: function () {
      let a = [];
      a = this.apiData;
      return a.sort((p1, p2) => {
        let modifier = 1;
        if (this.sortDirection === "desc") modifier = -1;
        if (p1[this.sortBy] == null || p2[this.sortBy] == null) {
          return 0 * modifier;
        }
        if (this.sortBy == "requestId") {
          if (parseInt(p1[this.sortBy]) < parseInt(p2[this.sortBy]))
            return -1 * modifier;
          if (parseInt(p1[this.sortBy]) > parseInt(p2[this.sortBy]))
            return 1 * modifier;
        }
        if (this.sortBy == "latency") {
          if (Number(p1[this.sortBy]) < Number(p2[this.sortBy]))
            return -1 * modifier;
          if (Number(p1[this.sortBy]) > Number(p2[this.sortBy]))
            return 1 * modifier;
        }
        if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
        if (p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
        return 0;
      });
    },
    formattedDatetime(this: { requestResponesDate: string | undefined, requestResponesNoteText: string | undefined}) {
      if (this.requestResponesDate && this.requestResponesNoteText){
        const formattedDate = new Date(this.requestResponesDate);
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
          timeZoneName: 'short',
        };
        const formatter = new Intl.DateTimeFormat('en-IN', options);
        const formatted_datetime = formatter.format(formattedDate);
        return this.requestResponesNoteText.replace("DATETIME", formatted_datetime);
      }
      else{
        debugger;
        return null
      }
      
    },
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("API Logs", ["API"]);
    });
  },
});
